import { Progress } from '@chakra-ui/react';
import ISO6391 from 'iso-639-1';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import baseApi from 'api/baseApi';
import documentApi from 'api/document/documentApi';
import languageApi from 'api/language/languageApi';
import operationApi from 'api/operation/operationApi';
import pageContentApi from 'api/page-content/pageContentApi';
import pageApi from 'api/page/pageApi';
import tableApi from 'api/table/tableApi';
import tableDataApi from 'api/tableData/tableDataApi';
import { useApi } from 'api/useApi';
import {
  useFwSettings,
  FwSpinner,
  useFwModule,
  FwModal,
} from 'components/base';
import { initializeTableCriteria } from 'components/tables/helpers/tableCriteriaHelpers';
import { LOCALES } from 'core/utils/constant';
import utils from 'core/utils/utils';

import Preferences from './Preferences';

const PreferencesContainer = () => {
  const { t } = useTranslation();

  const { dispatchAppPreference, setLocalMode } = useFwSettings();
  const { moduleRoutes } = useFwModule();

  const [options, setOptions] = useState(undefined);

  const [progress, setProgress] = useState({
    showModal: false,
    modalOptions: undefined,
    part1: undefined,
    part2: undefined,
  });

  const [getArgs] = useState([]);
  const { fetched, pending } = useApi(languageApi.getAll, getArgs);

  const getOptionByKey = (key: string) => ({
    key: key.toLowerCase(),
    value: key,
    text: ISO6391.getNativeName(key.toLowerCase()),
  });

  useEffect(() => {
    if (!pending && fetched) {
      const languageOptions = _.map(fetched?.languages, ({ key }) =>
        getOptionByKey(key)
      );

      // add default translation
      languageOptions.push(getOptionByKey(LOCALES['en']));

      // sorted alphabetically
      const sortLanguageOption = languageOptions.sort((a, b) =>
        a.key > b.key ? 1 : -1
      );

      setOptions(sortLanguageOption);
    }
  }, [fetched, pending]);

  const handleSetKey = useCallback(() => {
    // todo set in user instead or maybe refetch user from token
    // dispatchAppPreference({ appPreferenceID: key });
  }, []);

  // apply new preferences
  const handleEntityChange = useCallback(
    (data) => {
      // todo wip#585 value(s) will be overwritten by string values
      dispatchAppPreference(data);
    },
    [dispatchAppPreference]
  );

  // todo wip#664 refactor logic and nested fetches
  // todo wip#664 handle errors or browser losing internet connection
  const handleSetLocalMode = useCallback(
    // todo wip#664 fix types
    async (_e, data) => {
      if (navigator.onLine) {
        const localOnly = utils.getBoxChecked(data.value);

        // when going local only, prepare cached data for local-first behaviour
        if (localOnly) {
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: true,
          }));

          if (moduleRoutes) {
            // todo wip#664 evaluate impact
            // only work on custom modules
            const modules = moduleRoutes.filter((route) => !route.system);

            const pages = [];

            // trigger data fetches (service worker will cache all)
            for (let mIdx = 0; mIdx < modules.length; mIdx++) {
              // fetch pages
              const resM = await pageApi.getByModuleAndArea(
                modules[mIdx].key,
                null,
                {}
              );
              pages.push(...resM?.data?.pages);
            }

            if (pages) {
              for (let pIdx = 0; pIdx < pages.length; pIdx++) {
                setProgress((prevProgress) => ({
                  ...prevProgress,
                  part1: (100 * (pIdx + 1)) / pages.length,
                  part2: 0,
                }));

                const pageContents = pages[pIdx].pageContent;

                for (let pcIdx = 0; pcIdx < pageContents.length; pcIdx++) {
                  // fetch page contents
                  const resPC = await pageContentApi.getById(
                    pageContents[pcIdx].pageContentID,
                    {}
                  );
                  const pageContent = resPC?.data?.pageContent;

                  if (pageContent?.tableKey) {
                    // fetch corresponding table
                    const resT = await tableApi.getTable(
                      pageContent.tableKey,
                      {}
                    );
                    const table = resT?.data?.table;

                    if (table) {
                      // fetch table data
                      const resTd = await tableDataApi.getMany(
                        tableDataApi.buildQuery(
                          table.key,
                          '',
                          initializeTableCriteria(
                            table,
                            '',
                            null,
                            [],
                            null,
                            null,
                            null,
                            null
                          )
                        ),
                        null,
                        table,
                        null,
                        null,
                        null,
                        {}
                      );
                      const tableDataRows = resTd?.data?.tableData?.rows;

                      if (tableDataRows) {
                        for (
                          let tdrIdx = 0;
                          tdrIdx < tableDataRows.length;
                          tdrIdx++
                        ) {
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            part2: (100 * (tdrIdx + 1)) / tableDataRows.length,
                          }));

                          // todo wip#664 maybe just add all documents ID to an array and fetch later...
                          // ...this will improve code readability (decrease indentation)...
                          // ...and allow for more precise progress tracking

                          // fetch documents
                          await documentApi.getByID(
                            tableDataRows[tdrIdx].rowID,
                            '',
                            '',
                            '',
                            false,
                            {}
                          );

                          // fetch operations
                          await operationApi.getManyByDocID(
                            tableDataRows[tdrIdx].rowID,
                            {}
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: true,
            modalOptions: { content: t('Synchronization...') },
          }));

          // todo wip#664 how to wait for synchronization to finish before allowing network access?
          // going back online, synchronize (resend data to backend through network)
          baseApi.sync(() => {
            setProgress((prevProgress) => ({
              ...prevProgress,
              showModal: false,
              modalOptions: undefined,
            }));
          });
        }

        // store localMode status in settings
        setLocalMode(localOnly);

        if (localOnly) {
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: false,
          }));
        }
      } else {
        setProgress((prevProgress) => ({
          ...prevProgress,
          showModal: true,
          modalOptions: {
            content: t('This operation requires a stable internet connection'),
            cancelName: t('Ok'),
            onCancel: () => {
              setProgress((prevProgress) => ({
                ...prevProgress,
                showModal: false,
                modalOptions: undefined,
              }));
            },
          },
        }));
      }
    },
    [t, moduleRoutes, setLocalMode]
  );

  const props = {
    options,
    handleSetKey,
    handleEntityChange,
    handleSetLocalMode,
  };

  return options ? (
    <>
      <Preferences {...props} />
      {/* todo wip#664 build separate component and refactor Progress to FwProgress */}
      <FwModal
        noDimmerClick
        open={progress.showModal}
        content={
          progress.modalOptions ? undefined : (
            <>
              <>{t('common|Loading pages')}</>
              <Progress
                hasStripe
                isAnimated
                isIndeterminate={progress.part1 === undefined ? true : false}
                value={progress.part1 || 0}
                size="xs"
              />
              <br />
              <>{t('common|Loading data')}</>
              <Progress
                hasStripe
                isAnimated
                isIndeterminate={progress.part2 === undefined ? true : false}
                value={progress.part2 || 0}
                size="xs"
                colorScheme="green"
              />
            </>
          )
        }
        // state modal options must override other props
        {...progress.modalOptions}
      />
    </>
  ) : (
    <FwSpinner />
  );
};

export default PreferencesContainer;
