import _ from 'lodash';
import { any, func, InferProps } from 'prop-types';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import appPreferenceApi from 'api/appPreference/appPreferenceApi';
import { EntityInfo } from 'components';
import { FwInput, FwSegment, useFwAuth } from 'components/base';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { AppPreference, getAdminInputs, Input } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import { readSettings } from 'core/utils/storage';

const Preferences: FC<Props> = ({
  options,
  handleSetKey,
  handleEntityChange,
  handleSetLocalMode,
}) => {
  const { t } = useTranslation();

  const { user } = useFwAuth();
  const settings = readSettings();

  const [localModeDisabled, setLocalModeDisabled] = useState(false);

  const stateRef = useRef(
    new AppPreference({
      ...settings,
      appPreferenceID: user.appPreference.appPreferenceID,
    })
  );

  // todo consider moving this to EntityInfo
  const inputs = getAdminInputs(stateRef.current, null, t);

  // apply dropdown options in inputs
  inputs[0].dropdown = { dropdownID: 1, options };

  const inputsRef = useRef(inputs);

  const entityDoc = getEntityDoc(stateRef.current, inputs);

  const onDataChange = useCallback(
    (data) => {
      // todo wip #585 refactor value type mapping
      // turn data (string values) into newData (typed values)
      // based on input types and field keys
      const newData = _.reduce(
        data,
        (result, value, key) => {
          const inputType = (_.find(inputsRef.current, { key }) as Input)?.type;
          result[key] =
            inputType === 'CHECKBOX'
              ? value === 'true'
              : value; /* map value here */
          return result;
        },
        {}
      );

      stateRef.current = new AppPreference(_.assign(stateRef.current, newData));
      handleEntityChange(newData);
    },
    [handleEntityChange]
  );

  const onToggleLocalMode = useCallback(
    // todo wip#664 fix types
    (e, data) => {
      // todo wip#664 redesign UX
      // prevent multiple fast toggles
      setLocalModeDisabled(true);

      handleSetLocalMode(e, data);
    },
    [handleSetLocalMode]
  );

  return (
    <>
      {!settings.localMode && (
        <EntityInfo
          autosave
          entityDoc={entityDoc}
          icon="RiSoundModuleFill"
          label={t('common|Preferences')}
          api={appPreferenceApi}
          entityKey={stateRef.current.appPreferenceID}
          keyName={'appPreferenceID'}
          type={'appPreference'}
          onEntityChange={onDataChange}
          onSetKey={handleSetKey}
        />
      )}
      {/* todo wip#664 build separate component */}
      <FwSegment name={t('common|Offline')}>
        <FwInput
          disabled={localModeDisabled}
          name={t('common|Offline')}
          label={t('common|Enable offline mode')}
          value={`${settings.localMode}`}
          type={FIELD_TYPE.checkbox}
          onChange={onToggleLocalMode}
        />
      </FwSegment>
    </>
  );
};

const propTypes = {
  options: any.isRequired,
  handleSetKey: func.isRequired,
  handleEntityChange: func.isRequired,
  handleSetLocalMode: func,
};

type Props = InferProps<typeof propTypes>;

Preferences.propTypes = propTypes;

export default withTranslation()(Preferences);
