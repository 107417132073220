import axios, { AxiosPromise, CancelToken, AxiosError } from 'axios';
import _ from 'lodash';

import {
  addToIdb,
  deleteAllFromIdb,
  deleteFromIdb,
  getAllFromIdb,
} from 'core/utils/idb';
import Log from 'core/utils/log';
import { clearStorageAndRedirect, getToken } from 'core/utils/storage';

const getUrl = window.location;
const baseUrl = getUrl.protocol + '//' + getUrl.host + '/api/';

export type BaseApiParams = {
  cancelToken?: CancelToken;
  validErrors?: number[];
  localMode?: boolean;
};

export const showApiError = async (error: Partial<AxiosError>) => {
  const details = (error?.response?.data as { details: string })?.details;

  if (details) {
    console.error(details);
  }
};

const validateStatusHelper = (status: number, validErrors?: number[]) =>
  (200 <= status && status < 300) ||
  (validErrors && validErrors.includes(status));

class BaseApi {
  external: boolean;
  instance = axios.create();

  constructor(external = false) {
    this.external = external;

    if (!this.external) {
      this.instance.defaults.baseURL = baseUrl;
    }

    this.instance.interceptors.request.use(
      (config) => {
        if (!this.external) {
          const token = getToken();

          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } else {
          // todo investigate config for CORS
          //config.headers.common['Access-Control-Allow-Origin'] = '*';
          //config.headers.common['Access-Control-Allow-Methods'] =
          //  'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        showApiError(error);

        if (!this.external && error.response?.status === 401) {
          // log out unauthorized user
          clearStorageAndRedirect('');
        }

        return Promise.reject(error);
      }
    );
  }

  base =
    (func: string) =>
    <D>(url: string, params: BaseApiParams, data?: D) => {
      const extra = {
        ...params,
        validateStatus: (status: number) =>
          validateStatusHelper(status, params.validErrors),
      };

      return this.instance[func](..._.compact([url, data, extra])).catch(
        this.handleError
      );
    };

  get = <R>(url: string, params = {}): AxiosPromise<R> =>
    this.base('get')(url, params);

  post = <D, R>(
    url: string,
    data: D,
    params = {} as BaseApiParams
  ): AxiosPromise<R> => {
    // todo wip#664 refactor logic and http methods
    if (params.localMode) {
      delete params.localMode;

      // save in indexedDB for later synchronization
      addToIdb('Request', {
        method: 'POST',
        url,
        data: _.cloneDeep(data),
      });
    }

    return this.base('post')(url, params, data);
  };

  put = <D, R>(
    url: string,
    data: D,
    params = {} as BaseApiParams
  ): AxiosPromise<R> => {
    // todo wip#664 refactor logic and http methods
    if (params.localMode) {
      delete params.localMode;

      // save in indexedDB for later synchronization
      addToIdb('Request', {
        method: 'PUT',
        url,
        data: _.cloneDeep(data),
      });
    }

    return this.base('put')(url, params, data);
  };

  delete = <R>(url: string, params = {}): AxiosPromise<R> =>
    this.base('delete')(url, params);

  // todo wip#664 rename async synchronization function currently looking synchronous
  sync = (callback?: () => void) => {
    const handleResult = async (results = []) => {
      // resend requests then execute provided callback
      this.sendRequests(results).then(() => callback?.());
    };

    // asynchronously get all pending requests from indexedDB
    getAllFromIdb('Request', handleResult);
  };

  // todo wip#664 handle errors
  sendRequests = async (requests = []) => {
    // store all identifiers created by first document creation POST requests
    const postIdList = [];

    // resend pending queued requests
    while (requests?.length) {
      const request = requests.shift();

      // todo wip#664 refactor logic?
      if (request.method === 'POST') {
        const postIsNewDoc = !request.data?.document;

        if (!postIsNewDoc) {
          // get one id from new doc id list
          const newDocId = postIdList.shift();

          // set id in request
          request.data.document.documentID = newDocId;
          request.data.document.number = 0;
          // todo wip#664 need to update metadata?
        }

        await this.post(request.url, request.data).then((response) => {
          if (postIsNewDoc) {
            // store as new doc id
            // todo wip#664 fix type?
            postIdList.push((response?.data as { id: string })?.id);
          }

          deleteFromIdb('Request', request.id);
        });
      } else if (request.method === 'PUT') {
        await this.put(request.url, request.data).then(() =>
          deleteFromIdb('Request', request.id)
        );
      }
    }

    // todo wip#664 ensure no data will be lost before
    deleteAllFromIdb('Doc');
  };

  handleError = (error: { message: string }) => {
    if (axios.isCancel(error)) {
      console.error('Request canceled', error.message);
      Log.error('Request canceled', error.message);
    }
  };
}

export const BaseExternalApi = new BaseApi(true);

export default new BaseApi(false);
