// todo wip#664 consider renaming file to remove ambiguity idb vs. indexedDb
// todo wip#664 fix naming and refactor hardcoded strings
// todo wip#664 refactor logic
// todo wip#664 handle errors
// open or create IndexedDB
const dbName = 'MyDatabase';
const dbVersion = 1;
const request = indexedDB.open(dbName, dbVersion);

// create object store and define key path
request.onupgradeneeded = function (event) {
  const db = (event.target as IDBOpenDBRequest).result;
  db.createObjectStore('Doc', {
    keyPath: 'documentID',
  });
  // db.createObjectStore('Operation', {
  //   keyPath: 'operationID',
  // });
  db.createObjectStore('Request', {
    keyPath: 'id',
    autoIncrement: true,
  });
};

// handle errors
request.onerror = function (event) {
  console.error('IndexedDB error:', (event.target as IDBRequest).error);
};

// todo wip#664 fix types with generic parameters
// function to add data to the store
function addToIdb(storeName: string, data, callback?) {
  const addRequest = request.result
    .transaction([storeName], 'readwrite')
    .objectStore(storeName)
    .add(data);

  addRequest.onsuccess = function (event) {
    if (callback) {
      callback(null, (event.target as IDBRequest).result);
    }
  };

  addRequest.onerror = function (event) {
    console.error('Error adding data:', (event.target as IDBRequest).error);
  };
}

// todo wip#664 fix types with generic parameters
function getFromIdb(storeName: string, key: string, callback?) {
  const dbRequest = indexedDB.open(dbName, dbVersion);

  dbRequest.onsuccess = function (event) {
    const db = (event.target as IDBRequest).result;
    const transaction = db.transaction([storeName], 'readonly');
    const objectStore = transaction.objectStore(storeName);

    const getRequest = objectStore.get(key);

    getRequest.onsuccess = function (event) {
      const existingData = (event.target as IDBRequest).result;

      if (callback) {
        callback(existingData);
      }
    };

    getRequest.onerror = function (event) {
      console.error(
        'Error looking up data:',
        (event.target as IDBRequest).error
      );
    };
  };

  dbRequest.onerror = function (event) {
    console.error('IndexedDB error:', (event.target as IDBRequest).error);
  };
}

// todo wip#664 fix types with generic parameters
function getAllFromIdb(storeName: string, callback?) {
  const dbRequest = indexedDB.open(dbName, dbVersion);

  dbRequest.onsuccess = function (event) {
    const db = (event.target as IDBRequest).result;
    const transaction = db.transaction([storeName], 'readonly');
    const objectStore = transaction.objectStore(storeName);

    const getAllRequest = objectStore.getAll();

    getAllRequest.onsuccess = function (event) {
      const existingData = (event.target as IDBRequest).result;

      if (callback) {
        callback(existingData);
      }
    };

    getAllRequest.onerror = function (event) {
      console.error(
        'Error looking up data:',
        (event.target as IDBRequest).error
      );
    };
  };

  dbRequest.onerror = function (event) {
    console.error('IndexedDB error:', (event.target as IDBRequest).error);
  };
}

// todo wip#664 fix types with generic parameters
function addOrUpdateToIdb(storeName: string, data, callback?) {
  const dbRequest = indexedDB.open(dbName, dbVersion);

  dbRequest.onsuccess = function (event) {
    const db = (event.target as IDBRequest).result as IDBDatabase;
    const transaction = db.transaction([storeName], 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    // check if data with the same 'id' already exists
    const getRequest = objectStore.get(
      storeName === 'Doc'
        ? data.documentID
        : storeName === 'Operation'
        ? data.operationID
        : data.id
    );

    getRequest.onsuccess = function (event) {
      const existingData = (event.target as IDBRequest).result;

      if (existingData) {
        // data with the same 'id' exists, update it
        const updateRequest = objectStore.put(data);

        updateRequest.onsuccess = function (event) {
          console.log(
            'Data updated successfully:',
            (event.target as IDBRequest).result
          );
          if (callback) {
            callback(null, (event.target as IDBRequest).result);
          }
        };

        updateRequest.onerror = function (event) {
          console.error(
            'Error updating data:',
            (event.target as IDBRequest).error
          );
        };
      } else {
        // data with the same 'id' doesn't exist, add it
        addToIdb(storeName, data, callback);
      }
    };

    getRequest.onerror = function (event) {
      console.error(
        'Error looking up data:',
        (event.target as IDBRequest).error
      );
    };
  };

  dbRequest.onerror = function (event) {
    console.error('IndexedDB error:', (event.target as IDBRequest).error);
  };
}

// todo wip#664 fix types with generic parameters
function deleteFromIdb(storeName: string, key: string, callback?) {
  const dbRequest = indexedDB.open(dbName, dbVersion);

  dbRequest.onsuccess = function (event) {
    const db = (event.target as IDBRequest).result;
    const transaction = db.transaction([storeName], 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    const deleteRequest = objectStore.delete(key);

    deleteRequest.onsuccess = function (event) {
      if (callback) {
        callback((event.target as IDBRequest).result);
      }
    };

    deleteRequest.onerror = function (event) {
      console.error(
        'Error looking up data:',
        (event.target as IDBRequest).error
      );
    };
  };

  dbRequest.onerror = function (event) {
    console.error('IndexedDB error:', (event.target as IDBRequest).error);
  };
}

// todo wip#664 fix types with generic parameters
function deleteAllFromIdb(storeName: string, callback?) {
  const dbRequest = indexedDB.open(dbName, dbVersion);

  dbRequest.onsuccess = function (event) {
    const db = (event.target as IDBRequest).result;
    const transaction = db.transaction([storeName], 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    const deleteRequest = objectStore.clear();

    deleteRequest.onsuccess = function (event) {
      if (callback) {
        callback((event.target as IDBRequest).result);
      }
    };

    deleteRequest.onerror = function (event) {
      console.error(
        'Error looking up data:',
        (event.target as IDBRequest).error
      );
    };
  };

  dbRequest.onerror = function (event) {
    console.error('IndexedDB error:', (event.target as IDBRequest).error);
  };
}

export {
  addToIdb,
  addOrUpdateToIdb,
  deleteAllFromIdb,
  deleteFromIdb,
  getAllFromIdb,
  getFromIdb,
};
