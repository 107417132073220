import { useEffect, useState } from 'react';

import { getFromIdb } from 'core/utils/idb';

// todo wip#664 for now only supports document as entity
type UseIdbEntityState = {
  pending: boolean;
  entity: unknown;
};

// refactored state updater to ensure 'pending' is always set to false
const stateUpdater =
  (newState?: Partial<UseIdbEntityState>) => (prevState: UseIdbEntityState) => {
    return {
      ...prevState,
      ...newState,
      pending: false,
    };
  };

// todo wip#664 for now only supports document as entity
const useIdbEntity = (key: string) => {
  const [state, setState] = useState<UseIdbEntityState>({
    pending: true,
    entity: undefined,
  });

  useEffect(() => {
    if (key) {
      // try get from indexedDb
      getFromIdb('Doc', key, (data) => {
        let newPartialState: Partial<UseIdbEntityState> = undefined;

        if (data) {
          newPartialState = { entity: data };
        }

        setState(stateUpdater(newPartialState));
      });
    } else {
      // update state
      setState(stateUpdater());
    }
  }, [key]);

  return [state.pending, state.entity];
};

export default useIdbEntity;
