import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { AppPreference, FwSettingsProviderProps } from 'core/model';

type FwSettingsProps = FwSettingsProviderProps & {
  dispatchAppPreference?: Dispatch<SetStateAction<Partial<AppPreference>>>;
  dispatchSettings?: (
    settings: FwSettingsProviderProps,
    toggleColorMode: () => void
  ) => void;
  setLocalMode?: (localMode: boolean) => void;
};

const FwSettingsContext: Context<FwSettingsProps> = createContext({});

export { FwSettingsContext };
