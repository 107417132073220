import _ from 'lodash';
import { ChangeEvent } from 'react';

import { areaIsMain, getAreaBasePath } from 'components/base';
import {
  AppUser,
  Area,
  Filter,
  Identity,
  Input,
  Module,
  PageContent,
  Row,
  Template,
  Execution,
  Option,
} from 'core/model';
import { IMAGE_TYPES, typesAsArray } from 'core/utils/mimeTypes';
import { sortByStepRowColumnKey } from 'core/utils/sort';

import {
  COLOR,
  DEFAULT_OPTION,
  INVALID_FILE_TYPE,
  CONTENT_TYPE,
  FIELD_TYPE,
} from './constant';
import { getTemplateContents, getDefaultValueByType } from './form';

export type DataProps = { name?: string; value?: object | string };

class Utils {
  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  tryParse = (str: string) => {
    let parsed = true;
    try {
      JSON.parse(str);
    } catch (e) {
      parsed = false;
    }
    return parsed;
  };

  validatePassword = (password) => password && password.length > 5;

  // todo implement desired functionality of this function with date-fns (currently unused)
  // toMomentObject = (dateString, customFormat) => {
  // const DISPLAY_FORMAT = 'L';
  // const ISO_FORMAT = 'DD/MM/YYYY';
  //
  // const dateFormats = customFormat
  //     ? [customFormat, DISPLAY_FORMAT, ISO_FORMAT]
  //     : [DISPLAY_FORMAT, ISO_FORMAT];
  //
  // const date = moment(dateString, dateFormats, true);
  // return date.isValid() ? date.hour(12) : null;
  // };

  // format int as string with leading zero when needed
  toHourString = (hourInt) =>
    hourInt && Number.isInteger(hourInt)
      ? `${hourInt < 10 ? '0' : ''}${hourInt}`
      : '';

  // transform 'myText' to 'MyText'
  toPascalCase = (text) =>
    text && typeof text === 'string'
      ? text.charAt(0).toUpperCase() + text.slice(1)
      : '';

  // transform 'text IS text' to 'Text is text'
  toSentenceCase = (text) =>
    text && typeof text === 'string'
      ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
      : '';

  // transform an array to a string with separator '|'
  arrayToString = (array) => (array && _.isArray(array) ? array.join('|') : '');

  // only used for exceptionUser, exceptionURole
  filterDataByExceptions = (array, exceptionUser, exceptionURole) => {
    return array.filter((a) => {
      if (a.exceptionID !== null) {
        if (exceptionURole && !_.isEmpty(exceptionURole)) {
          const except = _.find(exceptionURole, ['exceptionID', a.exceptionID]);

          if (except) {
            return except.include;
          }
        }
        if (exceptionUser && !_.isEmpty(exceptionUser)) {
          const except = _.find(exceptionUser, ['exceptionID', a.exceptionID]);

          if (except) {
            return except.include;
          }
        }
      }
      return true;
    });
  };

  getColumnsDefaultData = (row) => [
    {
      fieldID: 1,
      value: row.rowID + '',
    },
    { fieldID: 2, value: row.status && row.status.name },
    { fieldID: 3, value: row.lastEdit.substr(0, 19).replace('T', ' ') },
    { fieldID: 4, value: row.creator },
  ];

  // check if object should be shown in provided area
  showInArea = ({ areaOnly, areas }: any, area) => {
    return (
      (!areaOnly && areaIsMain(area)) ||
      (area?.key && areas && _.indexOf(areas, area.key) >= 0)
    );
  };

  // read claims to determine user role
  getRole = (user) => {
    let role;

    if (
      user &&
      user.username &&
      user.claims &&
      user.claims.filter(
        (c) =>
          c.type ===
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ).length > 0
    ) {
      role = user.claims.filter(
        (c) =>
          c.type ===
          `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`
      )[0].value;
    }

    return role;
  };

  // make sure first level authorization is granted to specified user identity
  isAuthorized = (identity: Identity, area?: Area) => {
    const preventAccess =
      !identity ||
      (!area?.key && identity && !this.canAccess(identity, 'main')) ||
      (area?.key && identity && !this.canAccess(identity, area.key));

    return !preventAccess;
  };

  // read claims to determine access permission
  canAccess = (user: AppUser, type: string) => {
    let granted = false;

    if (
      user?.username &&
      user?.claims?.some((c) => c.type.startsWith('access/'))
    ) {
      granted =
        user.claims.filter(
          (c) =>
            _.toLower(c.type) === _.toLower(`access/${type}`) && c.value === '1'
        ).length > 0
          ? true
          : false;
    }

    return granted;
  };

  // read claims to determine perform permission
  canPerform = (user: AppUser, type: string) => {
    let granted = false;

    if (
      user?.username &&
      user?.claims?.some((c) => c.type.startsWith('perform/'))
    ) {
      granted =
        user.claims.filter(
          (c) =>
            _.toLower(c.type) === _.toLower(`perform/${type}`) &&
            c.value === '1'
        ).length > 0
          ? true
          : false;
    }

    return granted;
  };

  // query stringify
  stringify = (obj) => {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
      }
    }
    return str.join('&');
  };

  // sort and move down all empty string to bottom
  sortFieldWithFalsey = (array, field) =>
    array.sort((a, b) => {
      const posA = a[field];
      const posB = b[field];
      return (
        (posA === '' ? 1 : 0) - (posB === '' ? 1 : 0) ||
        +(+posA > +posB) ||
        -(+posA < +posB)
      );
    });

  // on multiDates change
  getDefaultDateTimePeriod = (val) => {
    const now = new Date();
    const year = now.getFullYear() + 1900;
    const month = now.getMonth() + 1;
    const day = now.getDate();

    const monthString = month < 10 ? `0${month}` : month;
    const dayString = day < 10 ? `0${day}` : day;

    const leftValue = val
      ? val.split('|')[0]
      : `${year}-${monthString}-${dayString}T00:00`;
    const rightValue = val
      ? val.split('|')[1]
      : `${year}-${monthString}-${dayString}T23:59`;

    return `${leftValue}|${rightValue}`;
  };

  // map form data to filters
  mapDataToFilters = (formData) =>
    _.map(
      formData,
      (value, property) =>
        new Filter({
          key: property,
          value,
        })
    );

  // get clickables by status
  getTemplateClickablesByStatus = (template, status) => {
    const clickables = [];

    if (template && !_.isEmpty(template.clickables)) {
      const filteredClickables = this.getClickablesByStatus(
        template.clickables,
        status
      );

      if (filteredClickables && filteredClickables.length > 0) {
        clickables.push(...filteredClickables);
      }
    }

    return clickables;
  };

  getClickablesByStatus = (clickables, status) =>
    clickables.filter((c) => c.fromStatus === status);

  // inject default option into a list option
  injectDefaultOptionToListOptions = (listOption) => {
    return [DEFAULT_OPTION, ...listOption];
  };

  // convert
  convertToOptions = (valueArray = []) => {
    return _.map(valueArray, (value) => ({ key: value, value, text: value }));
  };

  // convert to options and inject default option
  convertToOptionsWithDefault = (valueArray = []) => {
    return this.injectDefaultOptionToListOptions(
      this.convertToOptions(valueArray)
    );
  };

  // check if an object have a property key and its value is not empty
  isValueEmpty = (obj, key) => {
    // return true if the object doesn't have this key or the value of this object by key is empty. Return false otherwise
    return (
      !obj.hasOwnProperty(key) ||
      obj[key] === undefined ||
      obj[key] === null ||
      obj[key] === ''
    );
  };

  //
  getNameValueFromEData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    data?: DataProps
  ): { name: string; value: string | object; fillData?: object } => {
    const { name, value } = data || e.target;
    return { name, value, fillData: { [name]: value } };
  };

  // transform (e, data) params to Faster { field, value } data
  getFieldValueFromEData = (
    e: ChangeEvent<HTMLInputElement>,
    data: DataProps
  ) => {
    const { name, value } = this.getNameValueFromEData(e, data);
    return { field: name, value };
  };

  // transform { name, value } params to Faster { [field]: value } doc data
  getDocDataFromNameValue = ({ name, value }) => {
    return { [name]: value };
  };

  //
  isValidFormData = (form, currentData, key, newValue) => {
    const isValid = !_.some(form, (input) => {
      if (
        input.visible &&
        input.required &&
        ((input.key === key &&
          newValue ===
            '') /* compare with local change because it isn't update in currentData yet */ ||
          (input.key !== key &&
            currentData &&
            this.isValueEmpty(currentData, input.key)))
      ) {
        return true;
      }
    });

    return isValid;
  };

  transform = (obj, predicate) =>
    Object.keys(obj).reduce((memo, key) => {
      if (predicate(obj[key], key)) {
        memo[key] = obj[key];
      }
      return memo;
    }, {});

  omit = (obj, items) =>
    this.transform(obj, (value, key) => !items.includes(key));

  // only used for routing
  decodePath = (encoded, is3D) => {
    const len = encoded.length;
    let index = 0;
    const array = [];
    let lat = 0;
    let lng = 0;
    let ele = 0;

    while (index < len) {
      let b;
      let shift = 0;
      let result = 0;
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
      lat += deltaLat;

      shift = 0;
      result = 0;
      do {
        b = encoded.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const deltaLon = result & 1 ? ~(result >> 1) : result >> 1;
      lng += deltaLon;

      if (is3D) {
        // elevation
        shift = 0;
        result = 0;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        const deltaEle = result & 1 ? ~(result >> 1) : result >> 1;
        ele += deltaEle;
        array.push([lat * 1e-5, lng * 1e-5, ele / 100]);
      } else {
        array.push([lat * 1e-5, lng * 1e-5]);
      }
    }
    // var end = new Date().getTime();
    // console.log("decoded " + len + " coordinates in " + ((end - start) / 1000) + "s");
    return array;
  };

  columnWidthByColsSpan = (colspan, totalColspan) =>
    colspan ? Math.round((colspan * 16) / totalColspan) : null;

  maxColSpan = (colItems) => {
    const maxColSpanElt = _.maxBy(colItems, (item: any) => item.columnSpan);
    return maxColSpanElt ? maxColSpanElt.columnSpan : 1;
  };

  getColSpanInRow = (row, fieldCheckVisible) => {
    let totalSpanInRow = 0;

    const maxRowSpan = _.max(
      _.map(
        _.filter(fieldCheckVisible, (field) => field.row === row),
        (field) => (field.row || 0) + (field.rowSpan || 1)
      )
    );

    const elementsInRow = _.filter(
      fieldCheckVisible,
      (s) => s.row >= row && s.row < maxRowSpan
    );

    const colsSorted = [
      ..._.uniq(_.map(elementsInRow, (item) => item.column)),
    ].sort();

    // sum of all max(colSpan) in each column
    _.forEach(colsSorted, (c) => {
      const elts = _.filter(elementsInRow, (elt) => elt.column === c);
      totalSpanInRow += this.maxColSpan(elts);
    });

    return { totalSpanInRow, elementsInRow, colsSorted, maxRowSpan };
  };

  isGradientColor = (color) => {
    const colorLowercase = color.toLowerCase();

    if (
      colorLowercase.startsWith('linear-gradient') ||
      colorLowercase.startsWith('repeating-linear-gradient') ||
      colorLowercase.startsWith('radial-gradient') ||
      colorLowercase.startsWith('repeating-radial-gradient')
    ) {
      return true;
    }

    return false;
  };

  //return origin value when it's hexa color (start with #)
  //and lowercase value when it's contains in list of basic color or has rgb format (rgb(...))
  //otherwise return null (use theme color)
  getColor = (color) => {
    if (color) {
      if (color.startsWith('#')) {
        return color;
      } else if (this.isGradientColor(color)) {
        return color.toLowerCase();
      } else {
        const colorLowercase = color.toLowerCase();

        if (colorLowercase.startsWith('rgb') || COLOR[colorLowercase]) {
          return colorLowercase;
        }
      }
    }

    return null;
  };

  // when value of checkbox, tell if box is checked
  getBoxChecked = (value: string | number | boolean) => {
    return (
      value === true ||
      value?.toString().toLowerCase() === 'true' ||
      value === '1' ||
      value === 1
    );
  };

  // reduce bool array using && operator
  reduceAndBoolArray = (boolArray) => _.reduce(boolArray, (a, b) => a && b);

  // reduce bool array using || operator
  reduceOrBoolArray = (boolArray) => _.reduce(boolArray, (a, b) => a || b);

  // get sections from templates
  getSectionsFromTemplates = (templates) =>
    this.getContentsFromTemplatesByType(templates, CONTENT_TYPE.section);

  // get contents from templates
  getContentsFromTemplatesByType = (templates, contentType) =>
    this.getFlatSubArray(templates, [
      (t) => _.filter(getTemplateContents(t), (ct) => ct.type === contentType),
    ]);

  // get fields from sections
  getFieldsFromSections = (sections) =>
    this.getFlatSubArray(sections, [(s) => s.fields]);

  // get fields from templates
  getFieldsFromTemplates = (templates: Template[]): Input[] =>
    this.getFlatSubArray(templates, [
      (t: Template) =>
        _.filter(
          getTemplateContents(t),
          (ct) => ct.type === CONTENT_TYPE.section
        ),
      (s: PageContent) => s.fields,
    ]);

  // get fields from templates then distinct them by fieldKey
  getDistinctFieldsFromTemplates = (templates: Template[]) =>
    _.uniqBy(this.getFieldsFromTemplates(templates), 'key');

  // build a flat array from sub arrays in array
  getFlatSubArray = (array, predicates, compact = true) => {
    // push array into a stream
    let stream: any = _.chain(array);

    // flat map of sub arrays
    for (let i = 0; i < predicates.length; i++) {
      stream = stream.flatMap(predicates[i]);
    }

    if (compact) {
      // remove all falsey value: null, undefined
      stream = stream.compact();
    }

    // return
    return stream.value();
  };

  sortThenMapProcessSteps = (steps: Execution[]) =>
    _.map(sortByStepRowColumnKey(steps), (e) => new Execution(e));

  // determinate if key is a sub-field key of a collection
  isSubFieldDataKey = (k: string) => (k.match(/\|/g) || []).length >= 3;

  isSubField = (subKey: string, collectionKey: string) =>
    subKey.startsWith(`${collectionKey}|`);

  // remove index in itemKey
  removeIndexFromSubInputKey = (k: string) =>
    k.substring(0, k.lastIndexOf('|'));

  // get index in itemKey
  getIndexFromSubInputKey = (k: string) =>
    Number(k.substring(k.lastIndexOf('|') + 1, k.length));

  // is category then build the format key : 'fieldKey|catKey1'
  buildKeyAutoHideForCategory = (fieldKey: string, catKey: string) =>
    fieldKey + '|' + catKey;

  isStringDataHasFieldValue = (data, fKey, fVal) => {
    const dataParsed = this.tryParse(data) ? JSON.parse(data) : undefined;
    return dataParsed && dataParsed[fKey] === fVal;
  };

  // return file name from url of type .../filename.extension
  getNameFromUrl = (url) => {
    return this.getFullNameFromUrl(url).split('.')[0];
  };

  // return file name and extension from url of type .../filename.extension
  getFullNameFromUrl = (url) => {
    return url ? url.split('/').pop() : '';
  };

  // returns original filename from path
  getOriginalFilename = (jsonString: string) => {
    let filename = '';

    if (this.tryParse(jsonString)) {
      const fullname = JSON.parse(jsonString).filename;

      if (fullname) {
        const [name, extension] = fullname.split('.');
        filename = `${this.base64DecodeUnicode(name)}.${extension}`;
      }
    }

    return filename;
  };

  // returns json.path if parsed, else returns the original input
  getPath = (jsonString: string): string => {
    return this.tryParse(jsonString) ? JSON.parse(jsonString).path : jsonString;
  };

  // return json.data if parsed, else return undefined
  getData = (jsonString: string): string => {
    return this.tryParse(jsonString) ? JSON.parse(jsonString).data : '';
  };

  getOriginFileNameFromBase64OldFormat = (base64FullFileName) => {
    // old format: originFileName_index_datetime.ext
    // split to get fileName and extension
    const split = base64FullFileName.split('.');
    const originFileName = split[0].split('_')[0];

    // decode to get originFileName
    return `${this.base64DecodeUnicode(originFileName)}.${split[1]}`;
  };

  // decode base64
  base64DecodeUnicode = (base64Str) => {
    // bytestream to percent-encoding to original string
    return decodeURIComponent(
      _.map(window.atob(base64Str).split(''), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')
    );
  };

  splitKeyAutoHideForCategory = (composedKey) => {
    const keySplited = composedKey.split('|');
    const catKey = keySplited.pop();
    return { fieldKey: keySplited.join('|'), catKey };
  };

  fileValidated = (file, isImage = false) => {
    const validated = { result: true, invalidType: null };
    const splitExtension = file.name.split('.');

    if (splitExtension.length <= 1) {
      // cannot validate type
      validated.result = false;
      validated.invalidType = INVALID_FILE_TYPE.invalidFileExtension;
      //} else if (file.size / 1000 / 1000 > FILE_SIZE_LIMIT) {
      //  // file size is over limit
      //  validated.result = false;
      //  validated.invalidType = INVALID_FILE_TYPE.invalidSize;
    } else if (isImage) {
      // check type of uploaded image
      if (!typesAsArray(IMAGE_TYPES).includes(file.type)) {
        validated.result = false;
        validated.invalidType = INVALID_FILE_TYPE.invalidImageExtension;
      }
    }

    return validated;
  };

  buildUrlNavFromPath = (path, info) => {
    return `${path}/${info}`;
  };

  buildUrlNav = (area: Area, module?: Module, info?: string) => {
    const areaBasePath = getAreaBasePath(area);
    const moduleUrlPrefix = module ? `${_.toLower(module.key)}/` : '';
    const urlPrefix = `${areaBasePath}${moduleUrlPrefix}`;

    return info ? `${urlPrefix}${info}` : urlPrefix;
  };

  // open link in a new tab
  openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  sortOptionsByText = (options: Option[]) => {
    return options.sort((a, b) => {
      return a.text > b.text ? 1 : -1;
    });
  };

  getDefaultUrl = (modules, defaultModule) => {
    const url =
      modules && modules.length > 0
        ? defaultModule &&
          modules.filter((m) => m.key === defaultModule).length > 0
          ? `/${defaultModule.toLowerCase()}`
          : modules[0].path
        : undefined;

    return `${url || ''}`;
  };

  getMetaDataWithDocData = (metaData, docData) => {
    // clone and assign member from metaData to docData
    const mergedData = { ...docData };
    Object.assign(mergedData, metaData);

    return mergedData;
  };

  getFullRowData = (docRow: Row) => {
    const parsedData =
      docRow.data && typeof docRow.data === 'string'
        ? JSON.parse(docRow.data)
        : docRow.data || {};

    return this.getFullData(docRow, parsedData);
  };

  getFullData = (doc, docData) => {
    return { ...docData, ...doc.metaData };
  };

  filterVisible = (array) => _.filter(array, ({ visible }) => visible);
  filterExistent = (array) =>
    _.filter(array, ({ visible }) => !_.isNil(visible));

  getVisibleInputsFromSteps = (steps, getOnlyVisible) =>
    this.getFlatSubArray(steps, [
      // tODO for case show/hide step
      //step => getOnlyVisible ? this.filterVisible(step.contents) : this.filterExistent(step.contents),
      (step) => this.filterVisible(step.contents),
      (section) =>
        getOnlyVisible
          ? this.filterVisible(section.fields)
          : this.filterExistent(section.fields),
    ]);

  getDataForPopupProcess = (processEdits, docData) => {
    // update value of popup
    // - by docData if edit value is empty
    // - by defaultValue if docData is empty
    // - by empty value if input is readonly
    return _.chain(processEdits)
      .keyBy('key')
      .mapValues(({ key, value, input: { isReadOnly, type, dropdown } }) =>
        isReadOnly
          ? ''
          : value
          ? value
          : docData[key]
          ? type === FIELD_TYPE.collection
            ? this.getCollectionDataToArray(key, docData)
            : docData[key]
          : getDefaultValueByType(type, dropdown)
      )
      .value();
  };

  getCollectionDataToArray = (collectionKey: string, docData: object) => {
    let collectionData = [];
    const collectionLength = Number(docData[collectionKey] || 0);

    // has any data in doc
    if (collectionLength) {
      // get all subKeys
      const subKeys = _.filter(_.keys(docData), (subK) =>
        this.isSubField(subK, collectionKey)
      );

      // transform to array which has index removed in key
      collectionData = _.reduce(
        subKeys,
        (array, subKeyIndex) => {
          const index = this.getIndexFromSubInputKey(subKeyIndex) - 1;
          const subKey = this.removeIndexFromSubInputKey(subKeyIndex);

          if (!array[index]) {
            array[index] = {};
          }

          array[index][subKey] = docData[subKeyIndex];

          return array;
        },
        []
      );
    }

    return collectionData;
  };

  removeEmptyRowCollection = (collectionData: object[]) =>
    _.reject(collectionData, _.isEmpty);

  getCollectionDataToFieldValueIndex = (
    dataToUpdate: object,
    collectionData: object[]
  ) => {
    // create fieldValue with key is fieldKey|index
    collectionData.forEach((line, idx) => {
      _.forOwn(line, (val, key) => {
        if (!_.isNil(val)) {
          dataToUpdate[`${key}|${idx + 1}`] = val;
        }
      });
    });
  };

  getProcessEditsByDocData = (processEdits, docData) => {
    const edits = {};

    _.forEach(processEdits, ({ key, input: { type } }) => {
      if (type === FIELD_TYPE.collection) {
        // remove empty rows from collection
        const rowsArray = this.removeEmptyRowCollection(docData[key]);

        // keep info of length of collection (number of its rows)
        edits[key] = `${rowsArray.length}`;

        // assign sub-field data to object
        this.getCollectionDataToFieldValueIndex(edits, rowsArray);
      } else {
        edits[key] = docData[key] ? docData[key] : '';
      }
    });

    // transform object data to array of edits and return
    const submitEdits = [];
    _.forOwn(edits, (value, key) => submitEdits.push({ key, value }));
    return submitEdits;
  };

  getPageLimitCollectionKeyByTemplate = (
    limitPageByCollectionRow: [
      {
        downloadTemplate: string;
        collectionKey: string;
      }
    ],
    templateName: string
  ) =>
    limitPageByCollectionRow?.find((o) => o.downloadTemplate === templateName)
      ?.collectionKey ?? undefined;
}

export default new Utils();
