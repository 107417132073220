import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { RouteChildrenProps } from 'react-router';

import documentApi from 'api/document/documentApi';
import DocumentEditionHub from 'api/document/documentEditionHub';
import { useApi } from 'api/useApi';
import { useHub } from 'api/useHub';
import {
  FwModuleStoreProvider,
  FwSpinner,
  FwToast,
  useFwArea,
  useFwAuth,
  useFwModule,
  useFwTemplates,
} from 'components/base';
import useIdbEntity from 'core/hooks/useIdbEntity';
import { Document, UserFilter } from 'core/model';
import { ACTION, FORM_LAYOUT_TYPE } from 'core/utils/constant';
import { dateFormats, jsDateToString } from 'core/utils/date';
import { addOrUpdateToIdb } from 'core/utils/idb';
import { arrayToObject } from 'core/utils/logic';
import { readSettings } from 'core/utils/storage';
import useMountedComponentRef from 'core/utils/useMountedComponentRef';

import Doc from './Doc';

const DocContainer = ({ match }: RouteChildrenProps) => {
  const { id, type, templateId } = match.params as {
    id: string;
    type: string;
    templateId: string;
  };

  const settings = useMemo(() => {
    return readSettings();
  }, []);

  const { user: currentUser } = useFwAuth();
  const { area } = useFwArea();
  const { module } = useFwModule();
  const { templates } = useFwTemplates();

  const linkTemplateIDRef = useRef(
    (_.find(templates, { type: FORM_LAYOUT_TYPE.link }) || {}).templateId
  );
  const idRef = useRef(id);
  const mountedRef = useMountedComponentRef();
  const [document, setDocument] = useState<Document>();
  const [viewingUsers, setViewingUsers] = useState([]);
  const [autosave, setAutosave] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const [createArgs] = useState(id ? [] : [templateId, undefined, undefined]);
  const [fetchArgs, setFetchArgs] = useState(
    id
      ? /* missingTemps = false */
        [id, type, undefined, area?.key || undefined, false]
      : []
  );
  const [hubArgs, setHubArgs] = useState({ channel: id });
  const [pendingLocal, localDocument] = useIdbEntity(id);

  // gather minimal data for local mode logic
  const localModeRef = useRef({
    templateId,
    currentUser,
    localMode: settings.localMode,
    doc: undefined,
  });

  // create new document
  const { response: create, pending: pendingCreate } = useApi(
    !id && templateId ? documentApi.post : undefined,
    createArgs
  );

  // todo wip#664 no network access -> get from indexedDb
  // fetch from api
  const { fetched: fetch, pending: pendingFetch } = useApi(
    id || templateId ? documentApi.getByID : undefined,
    fetchArgs
  );

  // define hub handlers
  const addUserHandler = useCallback(
    (connectionId, username) => {
      if (mountedRef.current) {
        if (!viewingUsers || viewingUsers.length < 1) {
          FwToast.info('A user is viewing this document');
        }

        viewingUsers.push({ connectionId, username });
        setViewingUsers(_.uniqBy(viewingUsers, (vu) => vu.connectionId));
      }
    },
    [viewingUsers]
  );

  const acknowledgedHandler = useCallback(
    (channel, connectionId, username) => {
      if (idRef.current === channel && mountedRef.current) {
        viewingUsers.push({ connectionId, username });
        setViewingUsers(_.uniqBy(viewingUsers, (vu) => vu.connectionId));
      }
    },
    [viewingUsers]
  );

  const updatedHandler = useCallback(() => {
    if (mountedRef.current) {
      setShouldReload(true);
    }
  }, []);

  const removeConnectionHandler = useCallback(
    (connectionId) => {
      if (mountedRef.current) {
        _.remove(viewingUsers, { connectionId });
        setViewingUsers([...viewingUsers]);
      }
    },
    [viewingUsers]
  );

  // connect to hub
  useHub(id && !settings.localMode ? DocumentEditionHub : undefined, hubArgs, {
    addUserHandler,
    acknowledgedHandler,
    updatedHandler,
    removeConnectionHandler,
  });

  const reFetchDoc = useCallback(
    (docId) => {
      // re-init value to trigger re-render
      setDocument(undefined);
      setShouldReload(false);

      // fetch doc
      setFetchArgs([docId, type, undefined, area?.key || undefined, false]);

      // connect to hub
      setViewingUsers([]);
      setHubArgs({ channel: docId });
    },
    [area, type]
  );

  // api callbacks
  useEffect(() => {
    if (!pendingCreate && create) {
      const { data } = create;
      const localModeData = localModeRef.current;

      // if in local mode, store in indexedDB
      if (localModeData.localMode) {
        // todo wip#664 refactor and comment
        const now = new Date();
        const lastEdit = jsDateToString(now, dateFormats.isoShort);
        const fillUserFilters = localModeData.currentUser.filters?.filter(
          (f: UserFilter) => !f.action || f.action === ACTION.fill
        );
        const newDocData = fillUserFilters?.length
          ? /* initialize data with 'fill' user filters */
            JSON.stringify(arrayToObject(fillUserFilters))
          : '';

        // todo wip#664 consider removing doc from localModeData by handling async indexedDb operation
        // store in ref to avoid waiting for indexedDb slow transaction completion
        localModeData.doc = {
          documentID: `${data.id}`,
          number: data.number,
          key: '',
          active: false,
          status: 'Draft',
          data: newDocData,
          metaData: {
            FORMID: `${data.id}`,
            REFERENCEKEY: '',
            NUMBER: `${data.number}`,
            STATUS: 'Draft',
            USER: localModeData.currentUser.username,
            LASTEDIT: lastEdit,
          },
          template: {
            templateId: localModeData.templateId,
          },
          appUser: null,
          extendDocuments: [],
          lastEdit,
          lastView: null,
          viewed: null,
        };
        // todo wip#664 refactor indexedDb store names
        addOrUpdateToIdb('Doc', localModeData.doc);
      }

      // set in state
      setFetchArgs([data.id, type, undefined, area?.key || undefined, false]);
    }
  }, [area, type, pendingCreate, create]);

  useEffect(() => {
    if (!pendingFetch && !pendingLocal && fetch) {
      const localModeData = localModeRef.current;
      let document =
        (localModeData.localMode && (localDocument as Document)) ||
        fetch.document;

      // todo wip#664 improve comments
      if (localModeData.localMode) {
        if (!id) {
          // use locally-created doc instead of fetched (mocked)
          document = localModeData.doc;
        } else {
          // use cached fetch doc since none was locally-created
          localModeData.doc = document;
        }
      }

      if (document) {
        const template = _.find(templates, {
          templateId: document.template && document.template.templateId,
        });

        if (template) {
          document.template = template;

          const shouldAutosave = template.additionalData['autosave'];

          if (shouldAutosave) {
            setAutosave(shouldAutosave);
          }
        }

        setDocument(document);
      }
    }
  }, [
    pendingFetch,
    pendingLocal,
    fetch /* todo wip#664 id */,
    localDocument,
    templates,
  ]);

  // onRouteChange
  useEffect(() => {
    if (id && id !== idRef.current) {
      // update ref value
      idRef.current = id;

      reFetchDoc(id);
    }
  }, [id, reFetchDoc]);

  return !document ? (
    // todo improve -> error message instead of infinite loading?
    <FwSpinner />
  ) : (
    <FwModuleStoreProvider>
      <Doc
        module={module}
        autosave={autosave}
        document={document}
        linkTemplateID={linkTemplateIDRef.current}
        localMode={localModeRef.current.localMode}
        shouldReload={!autosave && shouldReload}
        reFetchDoc={reFetchDoc}
        viewingUsers={viewingUsers}
      />
    </FwModuleStoreProvider>
  );
};

const areEqual = (prevProps, nextProps) => {
  // render only if param id is changed
  const { id: prevId } = prevProps.match.params;
  const { id: nextId } = nextProps.match.params;

  return (!prevId && !nextId) || prevId === nextId;
};

export default React.memo(DocContainer, areEqual);
